const defaultParams = {
    sid: '86010005050001',
    username: '管理员',
    appid: 'wxa1ebd30b775eda4e',
    floor: 'F2',
    apiVersion: 'v1.0',
    floors: ['B1', 'F1', 'F2', 'F3', 'F4', 'F5']
    // 热力图数据记录条数限制
    // hotsize: 2000
};

export default defaultParams;