import { observable, configure, action, makeAutoObservable } from 'mobx';
import { message } from 'antd';
import {
    getQuery
} from '@shared/index';
import axios from "axios";
import to from 'await-to-js';

import API from "@config/api";


configure({ enforceActions: 'observed' });

class StoreFlow {

    constructor() {
        makeAutoObservable(this)
    }

    @observable ui = {
        loading: false,
    }

    @observable flowDataType = 0

    @observable data = {
        hotRank: [
            // {
			// 	"poiCount": 0,
			// 	"poiId": "ddd",
			// 	"poiName": "哈哈哈哈"
            // },
            // {
			// 	"poiCount": 0,
			// 	"poiId": "ddd",
			// 	"poiName": "哈哈哈哈"
			// }
        ],
        navCount: 0,
        queryCount: 0,
        total: 0,
        useCount: 0
    }

    @observable sideId = getQuery('sid') || 30188888
   
    @observable hotData = {
        data: []
    }
    @observable hotDataLength = 0;

    @action acUpdateflowDataType(type = 0) {
        this.flowDataType = type;
    }

    @action async acFetchHot(params) {
        this.ui.loading = true;
        // eslint-disable-next-line
        const [err, ret] = await to(
            axios({
                url: API['posdata.heat'],
                method: 'GET',
                params
            })
        );
        this.hotData = ret.data;
        this.hotDataLength = ret.data.length;
        this.ui.loading = false;

        if (!ret.data.length) {
            message.warn('暂无热力数据', 3);
        }
    }
    
    @action async acFetchFlowOverView(params) {
        // eslint-disable-next-line
        const [err, ret] = await to(
            axios({
                url: API['posdata.overview'],
                method: 'GET',
                params
            })
        );

        // ret.data.hotRank = [
        //     {
        //         "poiCount": 10,
        //         poiTotalCount: 20,
		// 		"poiId": "ddd",
		// 		"poiName": "哈哈哈哈"
        //     },
        //     {
        //         "poiCount": 15,
        //          poiTotalCount: 20,
		// 		"poiId": "ddd",
		// 		"poiName": "哈哈哈哈"
		// 	}
        // ]
        this.data = ret.data;
    }
};

export default new StoreFlow();