import EventEmitter from 'events';
import defaultParams from '../config/params';
import dayjs from 'dayjs';

dayjs.locale('zh-cn');



export function transformMillion (n) {
    return parseFloat((n / 100000000).toFixed(2));
}

export function getQuery(key) {
    // const parsed = queryString.parse(global.location.search);
    // return parsed[key] || '';
    return defaultParams[key] || '';
}


export function patchMonth(month) {
    if (+month < 10) {
        return '0' + month
    }
    return month;
}

export function isAuto() {
    if (!isAuto.cache) {
        isAuto.cache = {};
    }
    if (typeof isAuto.cache.auto === 'boolean') {
        return isAuto.cache.auto;
    }
    isAuto.cache.auto = getQuery('auto') === '1';
    return isAuto.cache.auto;
}


export function buildReducer(state, action, reducerMap) {
    const reducerHandler = reducerMap[action.type];
    if (typeof reducerHandler === 'function') {
        return reducerHandler(state, action.payload);
    }
    return state;
}

export function buildTagsMap(list) {
    return list.reduce((acc, cur) => {
        acc[cur.typeCode] = acc[cur.typeCode] || {};
        acc[cur.typeCode] = cur.tagList.reduce((innerAcc, innerCur) => {
            innerAcc[innerCur.c_code] = innerCur.c_name;
            return innerAcc;
        }, {})
        return acc;
    }, {})
}



export function getDateStart() {
    const limitD = `${dayjs().format("YYYY-MM-DD")} 00:00:00`;
    return new Date(limitD).getTime();
    
};
export function getDateEnd() {
    const limitD = `${dayjs().format("YYYY-MM-DD")} ${dayjs().format("HH:mm")}:00`;
    return new Date(limitD).getTime();
};

export const Events = new EventEmitter();