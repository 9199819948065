import React, {useState, useEffect} from "react";
import { configure } from "mobx";
import { inject, observer } from "mobx-react";

import Header from "./modules/Header";
import Side from "./modules/Side";
import SideFlow from "./modules/SideFlow";
import { CloseOutlined } from "@ant-design/icons";
import { getQuery } from "@shared/index";
import FloatBar from "./modules/FloatBar";
import TimePicker from "./modules/SideFlow/picker";
import Map from "./modules/Map";
import setHttpConfig from "@config/httpConfig";
import { hotMapApi, mapPoint } from "@config/iframeApi";
import axios from "axios";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "antd/dist/antd.css";
import "./App.scss";

setHttpConfig(axios);

configure({ enforceActions: true });


function App(props) {

    const hotsize = +getQuery('hotsize') || Infinity;
    
    const [showHotTips, setShowHotTips] = useState(false);
    
    useEffect(() => {
        setShowHotTips(props.StoreFlow.hotDataLength > hotsize);
    }, [hotsize, props.StoreFlow.hotDataLength])

    function closeHotTips() {
        setShowHotTips(false);
    }

    return (
        <div>
            <Router>
                <Header />
                <FloatBar />
                <Switch>
                    <Route exact path="/">
                        <div className="content">
                            <div className="content__side">
                                <Side />
                            </div>
                            <div style={{background: '#fff'}}  className="content__main">
                                <Map type="point" iframeSrc={mapPoint} />
                            </div>
                        </div>
                    </Route>

                    <Route path="/flow">
                        <div className="content">
                            <div className="content__side">
                                <SideFlow />
                            </div>
                            <div style={{background: '#fff'}} className="content__main">
                                {
                                    showHotTips
                                    ?  <div className="hot-tips">
                                            <div className="hot-tips__close">
                                                <CloseOutlined
                                                    title="关闭"
                                                    onClick={closeHotTips}
                                                    style={{
                                                        color: "#7C838D",
                                                        cursor: "pointer",
                                                        fontSize: '10px'
                                                    }}
                                                />
                                            </div>
                                        
                                            <div className="hot-tips__content">
                                                <div className="hot-tips__content__title">提示</div>
                                                <p>由于数据量过大，无法正常显示“流量热力图”请将查询区间控制在30天以内，系统可正常显示“流量热力图”。</p>
                                            </div>
                                        </div>
                                    : null
                                }
                               
                                <Map type="hot" iframeSrc={hotMapApi} />
                            </div>
                            <div className="flow-picker-bar">
                                <TimePicker />
                            </div>
                            <div className="flow-weight">
                                <div className="flow-weight__content">
                                    <div className="flow-weight__bar">
                                        <div className="flow-weight__bar__dot flow-weight__bar__dot--low"></div>
                                        <div className="flow-weight__bar__dot flow-weight__bar__dot--high"></div>
                                    </div>

                                    <div className="flow-weight__step">
                                        <div className="flow-weight__num">0</div>
                                        <div className="flow-weight__num">5000</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Route>
                  
                </Switch>
            </Router>

           
        </div>
    );
}


export default inject("StoreFlow")(observer(App));

