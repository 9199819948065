import { observable, configure, action, makeAutoObservable } from 'mobx';
import {
    getQuery
} from '@shared/index';


configure({ enforceActions: 'observed' });

class StoreFloor {
    constructor() {
        makeAutoObservable(this);
    }

    @observable path = window.location.pathname;

    @observable floorId = getQuery('floor') || 'F4';
    @observable clickedFloor = false;

    @action acUpdateFloorId(id) {
        this.floorId = id;
    }

    @action acUpdateClickedUi(isClicked) {
        this.clickedFloor = isClicked;
    }

};

export default new StoreFloor();