// import { message } from 'antd';
let resId = -100;
// let messageFn = null;
const setDefaultRqConfig = (axios) => {
    if (resId < 0) {
        resId = axios.interceptors.response.use((res) =>  {
            // const msg = res.data.msg;
            // if (res.data.status !== 200 && msg) {
            //     messageFn = message.error(msg, 3, () => {
            //         messageFn = null;
            //     });
            // }
            return res.data;
        });
    }
}
export default setDefaultRqConfig;