import React, { useEffect, useState } from "react";
import useRouter from "use-react-router";
import "./index.scss";
import {
    getQuery
} from '@shared/index';

const getTitle = (pathname) => {
    console.log(pathname);
    const titleMap = {
        '/flow': '流量看板'
    }
    return titleMap[pathname] || '定位信标管理';
}
const Header = (props) => {
   
    const { history } = useRouter();
    const [user, setUser] = useState('');
    const title = getTitle(history.location.pathname);
    useEffect(() => {
        setUser(getQuery('username'));
    }, [])
   
    return (
        <div className='header'>
            <div className="logo"><i className="icon-logo"></i>{title}</div>
            <div className="user"><i className="icon-user"></i>{user}</div>
        </div>
    );
};

export default Header;