import { observable, configure, action, makeAutoObservable } from 'mobx';

import axios from "axios";
import to from 'await-to-js';

import API from "@config/api";


configure({ enforceActions: 'observed' });

class StoreBluetooth {

    constructor() {
        makeAutoObservable(this)
    }

    @observable ui = {
        loading: false,
    }

    @observable data = {
        list: [],
        overViewList: {
            abnormal: {
                abnormals: [],
                count: 0
            },
            lose: {
                abnormals: [],
                count: 0
            },
            normal: {
                abnormals: [],
                count: 0
            },
            total: 0
        }
    }

    @observable detailData = {
        floorId: '',
        list: [],
        siteId: ''
    }

    @observable clickedPoint = {
        floorId: '',
        id: '',
        x: '',
        y: ''
    }

    @action acUpdateClickedPoint(point) {
        this.clickedPoint = point;
    }
    
    @action async acFetchManageOverView(params) {
        // eslint-disable-next-line
        const [err, ret] = await to(
            axios({
                url: API['manage.overview'],
                method: 'GET',
                params
            })
        );
        this.data.overViewList = ret.data;
    }

    @action async acFetchManageDetail(params) {
        // eslint-disable-next-line
        const [err, ret] = await to(
            axios({
                url: API['manage.details'],
                method: 'GET',
                params
            })
        );
        this.detailData = ret.data;
    }
};

export default new StoreBluetooth();