// import StoreGlobal from './StoreGlobal';
import StoreBluetooth from './StoreBluetooth';
import StoreFlow from './StoreFlow';
import StoreFloor from './StoreFloor';
import StoreGlobal from './StoreGlobal';



export default {
    StoreGlobal,
    StoreFlow,
    StoreBluetooth,
    StoreFloor
};