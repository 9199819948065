import React from "react";
import dayjs from "dayjs";
import "./picker.scss";
import { DatePicker } from "antd";


import { observer, inject } from "mobx-react";


import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const { RangePicker } = DatePicker;
dayjs.locale('zh-cn');
function Picker(props) {
    const { StoreGlobal, StoreFlow } = props;
    
    const onDateChange = function (e) {
        const start = e[0]._d.getTime();
        const end = e[1]._d.getTime();
        console.log(start, end);
        StoreGlobal.acUpdateDateStart(start);
        StoreGlobal.acUpdateDateEnd(end);
    };


    const limitD = `${dayjs().format("YYYY-MM-DD")}`;
    const limitH = `${dayjs().format("HH:mm")}`;

    

    return (
        <div className="time-picker">
            {StoreFlow.flowDataType === 0 ? (
                <>
                    <div className="time-picker__label time-picker__layout">
                        数据截止时间：<span>{limitD}</span>
                        <span>{limitH}</span>
                    </div>
                </>
            ) : (
                <>
                    <div className="time-picker__label">时间筛选：</div>
                    <RangePicker
                        locale={locale}
                        onChange={onDateChange}
                        suffixIcon={<div className="date-icon"></div>}
                        allowClear={false}
                        placeholder={["开始日期", "结束日期"]}
                        separator="至"
                        className="time-picker-component"
                    />
                </>
            )}
        </div>
    );
}

export default inject("StoreGlobal", "StoreFlow")(observer(Picker));
