import React, { useRef, useEffect } from "react";
import { Spin } from "antd";
import {toJS} from 'mobx';
import { observer, inject } from "mobx-react";

import "./index.scss";


const Map = (props) => {
    const { StoreBluetooth, StoreFloor, StoreFlow, type } = props;
    const iframeRef = useRef(null);
    
    const loading = StoreFlow.ui.loading
    
    
   
    const {
        iframeSrc
    } = props;


    useEffect(() => {
        if (type === 'point') {
            if (iframeRef.current) {
                // 渲染楼层数据
                const detailData = toJS(StoreBluetooth.detailData);

                if (detailData.floorId) {
                    iframeRef.current.contentWindow.postMessage(
                        {
                            type: 'renderMap',
                            data: toJS(StoreBluetooth.detailData)
                        },
                        '*'
                    );
                }
              
            }
        }
        
        return () => {
           // cleanup
        }
    }, [StoreBluetooth.detailData, type]);

    useEffect(() => {
        if (type === 'point') {
            const clickedPoint = toJS(StoreBluetooth.clickedPoint);
            if (clickedPoint.floorId) {
                if (iframeRef.current) {
                    // 渲染楼层数据
                    iframeRef.current.contentWindow.postMessage(
                        {
                            type: 'renderPoint',
                            data: toJS(StoreBluetooth.clickedPoint)
                        },
                        '*'
                    );
                }
            }
           
           
        }
        return () => {
            // cleanup
        }
    }, [StoreBluetooth.clickedPoint, type])


    useEffect(() => {
        
        if (type === 'hot') {
            if (iframeRef.current) {
                const d = toJS(StoreFlow.hotData);
                iframeRef.current.contentWindow.postMessage(
                    {
                        type: 'renderHot',
                        data: {
                            floorId: StoreFloor.floorId,
                            siteId: StoreFlow.sideId,
                            list: d
                        }
                    },
                    '*'
                );
            }
           
        }
        return () => {
            // cleanup
        }
    }, [StoreFloor.floorId, StoreFlow.hotData, StoreFlow.sideId, type])
    

    return (
        
    <div className='map-wrapper'>
        <Spin spinning={loading} size="large" wrapperClassName="map-loading">
        <iframe
        width='100%'
        height='100%'
        id='iframe'
        src={iframeSrc}
        ref={iframeRef}
        frameBorder={0}
        title='map'
        name="iframe"
    ></iframe>
           </Spin>     
    </div>
        
        
    );
};

export default inject("StoreBluetooth", "StoreFloor", "StoreFlow")(observer(Map));
