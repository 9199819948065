import * as R from "ramda";

// import version from './apiVersion';

// sid=88888888，fid=F4，appId=wxa1ebd30b775eda4e

const host =
    process.env.NODE_ENV === "production"
        ? 'https://xhcd.kingwayit.com/posapp/api'
        : 'https://xhcd.kingwayit.com/posapp/api';

const api = {
    'manage.details': '/v1.0/beacon/manage/details',
    'manage.overview': '/v1.0/beacon/manage/overview',
    'posdata.overview': '/v2.0/posdata/overview',
    'posdata.heat': '/v1.0/posdata/heat'
};

const prependHost = (val, key) => host + val;

export default R.mapObjIndexed(prependHost, api);
