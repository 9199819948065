import { observable, configure, action, makeAutoObservable } from 'mobx';
import { getQuery, getDateStart, getDateEnd } from "@shared/index";

configure({ enforceActions: 'observed' });



class Global {

    constructor() {
        makeAutoObservable(this);
    }

 
    @observable sid = getQuery("sid");
    @observable appid = getQuery("appid");
    @observable size = getQuery("size") || 5;


    @observable dateStart = getDateStart();
    @observable dateEnd = getDateEnd();

    @action acUpdateDateStart(date) {
        this.dateStart = date;
    }

    @action acUpdateDateEnd(date) {
        this.dateEnd = date;
    }
    
};

export default new Global();