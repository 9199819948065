import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import { getQuery } from "@shared/index";
import params from '../../config/params';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './index.scss';

const floors = params.floors;

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-arrow-wrapper" onClick={onClick}>
          <CaretDownOutlined style={{fontSize: '18px', color: '#898B92', cursor: 'pointer'}} />
      </div>
    );
}
  
function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slider-arrow-wrapper"onClick={onClick}>
            <CaretUpOutlined style={{fontSize: '18px', color: '#898B92', cursor: 'pointer'}} />
        </div>
    );
}

const settings = {
    speed: 200,
    draggable: false,
    slidesToShow: 4,
    // slidesToScroll: 4,
    swipe: false,
    focusOnSelect: false,
    infinite: false,
    //initialSlide: 2,
    vertical: true,
    className: 'cus-slider',
    //adaptiveHeight: true,
    nextArrow: <SampleNextArrow className="sample-slick-arrow sample-slick-next" />,
    prevArrow: <SamplePrevArrow className="sample-slick-arrow sample-slick-prev" />
   // verticalSwiping: true,
};








function getSwiperIdx(floors, floorId) {
    return floors.findIndex(fId => fId === floorId) || 1;
}


function Floatbar(props) {
    const { StoreBluetooth, StoreFloor } = props;
    const sliderRef = useRef(null);
    
    const isFlowPage = StoreFloor.path === '/flow';
   
   

    const handleSlickTo = (el, idx) => {

        // if (sliderRef.current) {
        //     sliderRef.current.slickGoTo(idx);
        // }

        // 更新楼层
        StoreFloor.acUpdateFloorId(el);

        if (!isFlowPage) {
            StoreFloor.acUpdateClickedUi(true);
        }
       
        
    }


    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(getSwiperIdx(floors, StoreFloor.floorId));
        }


        if (!isFlowPage) {
            StoreBluetooth.acFetchManageDetail({
                fid: StoreFloor.floorId,
                sid: getQuery('sid')
            });
        }


        // if (isFlowPage) {

        //     let msg = ''
        //     if (!StoreGlobal.sid) {
        //         msg = 'query中无sid';
               
        //     } else if (!StoreGlobal.appid) {
        //         msg = 'query中无appid';
        //     }
    
        //     if (msg) {
        //         message.error(msg, 3);
        //         return;
        //     }

        //     StoreFlow.acFetchHot({
        //         fid:  StoreFloor.floorId,
        //         appid: StoreGlobal.appid,
        //         sid: StoreGlobal.sid,
        //         etime: StoreGlobal.dateEnd,
        //         stime: StoreGlobal.dateStart
        //     });
        // }

       
        return () => {
            // cleanup
        }
    }, [StoreBluetooth, StoreFloor.floorId, isFlowPage])

    return (
        <div className="float-bar">
           <div className="floors">

                <Slider 
                    ref={sliderRef}  
                    {...Object.assign({}, settings, {initialSlide: getSwiperIdx(floors, StoreFloor.floorId)}  )}
                >
                        {
                            floors.map((el, idx) => {
                                return (
                                    <div onClick={handleSlickTo.bind(null, el, idx)} className="slider-item" key={el}>
                                        {el}
                                    </div>
                                )
                            })
                        }
                </Slider>
           </div>
           {/* <div className="size-control-wrapper">
              <SizeControl />
           </div> */}
           
        </div>
    );
}

export default inject("StoreBluetooth", "StoreFloor", "StoreGlobal", "StoreFlow")(observer(Floatbar));