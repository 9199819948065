import React, { useState, useEffect } from "react";
import { message } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import dayjs from "dayjs";
import { getQuery } from "@shared/index";

import { CloseOutlined } from "@ant-design/icons";

import cls from "classnames";

import { observer, inject } from "mobx-react";

import "./index.scss";

const getCurTime = (dayjs) =>
    dayjs()
        .format("HH:mm:ss")
        .split(" ")
        .map((el) => `${el}`)
        .join("");
const getCurDay = (dayjs) =>
    dayjs()
        .format("YYYY年 MM月DD日")
        .split(" ")
        .map((el) => `${el}`)
        .join("");
function RenderTime() {
    const [curTime, setCurTime] = useState(getCurTime(dayjs));
    const [curDay, setcurDay] = useState(getCurDay(dayjs));
    useEffect(() => {
        const timer = setInterval(() => {
            setCurTime(getCurTime(dayjs));
            setcurDay(getCurDay(dayjs));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className="time">
            <div className="time__hd">{curTime}</div>
            <div className="time__bd">{curDay}</div>
        </div>
    );
}

function RenderCount({ count }) {
    return (
        <div className="count">
            <div className="count__hd">蓝牙总数</div>
            <div className="count__bd">{count}</div>
        </div>
    );
}

function RenderStateListItem(props) {
    const { color, ikey, title, data, onSelect = function () {}, active } = props;

    const clsn = cls({
        "state-item": true,
        "state-item--active": !!active,
    });

    return (
        <div onClick={onSelect} className={clsn}>
            <div
                style={{ backgroundColor: color }}
                className="state-item__icon"
            >
                <i className={`icon icon-${ikey}`}></i>
            </div>
            <div className="state-item__sub">
                <div className="state-item__sub__title">{title}</div>
                <div className="state-item__sub__count">{data.count}</div>
            </div>
        </div>
    );
}

function RenderStateList(props) {
    const { onSelect } = props;
    const [activeIdx, setActiveIdx] = useState(0);

    const childrens = React.Children.map(props.children, (child, idx) => {
        if (process.env.NODE_ENV === "dev" && child.type.name !== "RenderStateListItem")
            // eslint-disable-next-line
            throw "RenderStateList child component must be RenderStateListItem";
        return React.cloneElement(child, {
            active:
                typeof props.selectIdx === "number"
                    ? props.selectIdx === idx
                    : activeIdx === idx,
            onSelect: function (e) {
                setActiveIdx(idx);
                onSelect.call(null, idx);
            },
        });
    });
    return <div className="state-list">{childrens}</div>;
}

const stateConsts = {
    normal: {
        color: "#00B929",
        title: "运行正常",
        ikey: 'normal'
    },
    abnormal: {
        color: "#FF4041",
        title: "运行异常",
        ikey: 'warn'
    },
    lose: {
        color: "#FFCC40",
        title: "失联状态",
        ikey: 'lose'
    },
};

function buildStateList(overViewList, stateConsts) {
    return [
        {
            data: overViewList.normal,
            ...stateConsts.normal,
        },
        {
            data: overViewList.abnormal,
            ...stateConsts.abnormal,
        },
        {
            data: overViewList.lose,
            ...stateConsts.lose,
        },
    ];
}

function Side(props) {
    const { StoreBluetooth, StoreFloor } = props;
    const { overViewList } = StoreBluetooth.data;

    const [childPanelData, setChildPanleData] = useState({
        title: "",
        list: [],
    });
    const [curDataIdx, setCurDataIdx] = useState(-1);
    const [curPanelDataIdx, setCurPanelDataIdx] = useState(-1);

    useEffect(() => {
        const sid = getQuery("sid");
        if (!sid) {
            message.error("query中无sid", 3);
            return;
        }
        StoreBluetooth.acFetchManageOverView({
            sid,
        });
    }, [StoreBluetooth]);

    const rebuildStateList = buildStateList(overViewList, stateConsts);

    const onSelect = function (idx) {
        setCurDataIdx(idx);
        setCurPanelDataIdx(-1);
        setChildPanleData({
            title: rebuildStateList[idx].title,
            list: rebuildStateList[idx].data.beacons.map((el) => {
                return {
                    ...el,
                    color: rebuildStateList[idx].color,
                };
            }),
        });
    };

    const resetIdx = function () {
        setCurDataIdx(-1);
        
    };

    const onCloseSidePanel = function () {
        resetIdx();
    };

    const sideCls = cls({
        side__bd: true,
        "side__bd--open": curDataIdx !== -1,
        "side__bd--close": curDataIdx === -1,
    });

    const onSelectPanelItem = function (record, idx) {

        // 更新楼层
        
        StoreFloor.acUpdateFloorId(record.floorId);
        // 更新点击的point id
        StoreBluetooth.acUpdateClickedPoint(record);
        // 来自非楼层bar的点击
        StoreFloor.acUpdateClickedUi(false);
        setCurPanelDataIdx(idx);
    };

    useEffect(() => {
        // 来自非楼层的点击
        if (StoreFloor.clickedFloor) {
            setCurPanelDataIdx(-1);
        }
    }, [StoreFloor.clickedFloor]);

    return (
        <div className="side">
            <div className="side__hd">
                <div className="side__hd__overview">
                    <div className="side__hd__overview__hd">
                        <div className="time-wrapper">
                            <RenderTime />
                        </div>
                        <div className="count-wrapper">
                            <RenderCount count={overViewList.total} />
                        </div>
                    </div>
                    <RenderStateList selectIdx={curDataIdx} onSelect={onSelect}>
                        {rebuildStateList.map((el, idx) => {
                            return <RenderStateListItem key={idx} {...el} />;
                        })}
                    </RenderStateList>
                </div>
            </div>

            {curDataIdx !== -1 && (
                <Scrollbars
                    autoHide
                    style={{ height: "100vh", width: "200px" }}
                >
                    <div className={sideCls}>
                        <div className="side__bd__hd">
                            <div>
                                {childPanelData.title}(
                                {childPanelData.list.length})
                            </div>
                            <div>
                                <CloseOutlined
                                    onClick={onCloseSidePanel}
                                    title="关闭"
                                    style={{
                                        color: "#7C838D",
                                        cursor: "pointer",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="side__bd__bd">
                            <div className="state-panel-list">
                                {childPanelData.list.map((el, idx) => {
                                    const clss = cls({
                                        "state-panel-list__item": true,
                                        "state-panel-list__item--active":
                                            idx === curPanelDataIdx,
                                    });
                                    return (
                                        <div
                                            onClick={onSelectPanelItem.bind(
                                                null,
                                                el,
                                                idx
                                            )}
                                            className={clss}
                                            key={el.id}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: el.color,
                                                }}
                                                className="state-panel-list__item__dot"
                                            ></div>
                                            <div className="state-panel-list__item__content">
                                                <div className="state-panel-list__item__name">
                                                    {el.name}
                                                </div>
                                                <div className="state-panel-list__item__addr">
                                                    位置：{el.floorId}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            )}
        </div>
    );
}

export default inject("StoreBluetooth", "StoreFloor")(observer(Side));
