import React, { useEffect } from "react";
import { message } from "antd";
import { getDateStart, getDateEnd } from "@shared/index";
import cls from "classnames";
import { observer, inject } from "mobx-react";
import "./index.scss";

function SideFlow(props) {
    const { StoreFlow, StoreFloor, StoreGlobal } = props;
    const { data } = StoreFlow;

    useEffect(() => {
        let msg = "";
        if (!StoreGlobal.sid) {
            msg = "query中无sid";
        } else if (!StoreGlobal.appid) {
            msg = "query中无appid";
        }

        if (msg) {
            message.error(msg, 3);
            return;
        }

        const params = Object.assign(
            {
                fid: StoreFloor.floorId,
                appid: StoreGlobal.appid,
                sid: StoreGlobal.sid,
            },
            StoreFlow.flowDataType === 1
                ? {}
                : {
                    etime: StoreGlobal.dateEnd,
                    stime: StoreGlobal.dateStart,
                  }
        )

        StoreFlow.acFetchFlowOverView(params);

    }, [StoreFloor.floorId, StoreFlow, StoreFlow.flowDataType, StoreGlobal.appid, StoreGlobal.dateEnd, StoreGlobal.dateStart, StoreGlobal.sid]);

  

    const onChangeTab = (idx) => {
        StoreFlow.acUpdateflowDataType(idx);
        if (idx === 0) {
            StoreGlobal.acUpdateDateStart(getDateStart());
            StoreGlobal.acUpdateDateEnd(getDateEnd());
        } else {
            StoreGlobal.acUpdateDateStart('');
            StoreGlobal.acUpdateDateEnd('');
        }
    };

    useEffect(() => {
        let msg = "";
        if (!StoreGlobal.sid) {
            msg = "query中无sid";
        } else if (!StoreGlobal.appid) {
            msg = "query中无appid";
        }

        if (msg) {
            message.error(msg, 3);
            return;
        }

        if (StoreFlow.flowDataType === 0 || StoreFlow.flowDataType === 1) {
          
            StoreFlow.acFetchHot({
                fid: StoreFloor.floorId,
                appid: StoreGlobal.appid,
                sid: StoreGlobal.sid,
                etime: StoreGlobal.dateEnd,
                stime: StoreGlobal.dateStart,
            });
        }

        return () => {

        }

        
    }, [StoreFlow.flowDataType, StoreFloor.floorId, StoreGlobal.sid, StoreGlobal.appid, StoreGlobal.dateEnd, StoreGlobal.dateStart, StoreFlow]);

    
   

    useEffect(() => {
        let msg = "";
        if (!StoreGlobal.sid) {
            msg = "query中无sid";
        } else if (!StoreGlobal.appid) {
            msg = "query中无appid";
        }

        if (msg) {
            message.error(msg, 3);
            return;
        }
        
        if (StoreFlow.flowDataType === 1) {
            if (!StoreGlobal.dateStart  || !StoreGlobal.dateEnd) {
                return;
            }

            StoreFlow.acFetchHot({
                fid: StoreFloor.floorId,
                appid: StoreGlobal.appid,
                sid: StoreGlobal.sid,
                etime: StoreGlobal.dateEnd,
                stime: StoreGlobal.dateStart,
            });
         
            StoreFlow.acFetchFlowOverView({
                fid: StoreFloor.floorId,
                appid: StoreGlobal.appid,
                sid: StoreGlobal.sid,
                etime: StoreGlobal.dateEnd,
                stime: StoreGlobal.dateStart
            });
        }
        return () => {
            
        }
    }, [StoreGlobal.dateStart, StoreGlobal.dateEnd, StoreGlobal.sid, StoreGlobal.appid, StoreFlow, StoreFloor.floorId])



    return (
        <div className="side side-flow">
            <div className="side-flow__hd">
                <div className="side-flow__tab">
                    {["今日数据", "历史数据"].map((el, idx) => {
                        const dataTypeCls = cls({
                            "side-flow__tab__item": true,
                            "side-flow__tab__item--active":
                                idx === StoreFlow.flowDataType,
                        });

                        return (
                            <div
                                key={idx}
                                onClick={onChangeTab.bind(null, idx)}
                                className={dataTypeCls}
                            >
                                {el}
                            </div>
                        );
                    })}
                </div>

                <div className="side-flow__sumary">
                    <div className="side-flow__sumary__hd">
                        <h4>{StoreFlow.flowDataType === 0 ? '今日访问量' : '总访问量'}</h4>
                    </div>
                    <div className="side-flow__sumary__bd">
                        <h2>{data.total}</h2>
                    </div>
                </div>

                <div className="side-flow__counts">
                    <div className="side-flow__counts__item">
                        <div className="side-flow__counts__item__icon">
                            <i className="side-flow__counts__item__icon__nav icon"></i>
                        </div>
                        <div className="side-flow__counts__item__content">
                            <div className="side-flow__counts__item__hd">
                                查询次数
                            </div>
                            <div className="side-flow__counts__item__bd">
                                {data.useCount}
                            </div>
                        </div>
                    </div>
                    <div className="side-flow__counts__item">
                        <div className="side-flow__counts__item__icon side-flow__counts__item__icon--blue">
                            <i className="side-flow__counts__item__icon__query icon"></i>
                        </div>
                        <div className="side-flow__counts__item__content">
                            <div className="side-flow__counts__item__hd">
                                导航次数
                            </div>
                            <div className="side-flow__counts__item__bd">
                                {data.navCount}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="side-flow__rank">
                    <div className="side-flow__rank__hd">
                        <h3>热门业务排行 TOP 5</h3>
                    </div>
                    <div className="side-flow__rank__bd">
                        <div className="side-flow__rank__list">
                            {data.hotRank.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="side-flow__rank__list__item"
                                    >
                                        <div className="side-flow__rank__list__hd">
                                            {idx + 1}
                                        </div>
                                        <div className="side-flow__rank__list__bd">
                                            <div className="side-flow__rank__list__item__des">
                                                <div className="side-flow__rank__list__item__name">
                                                    {el.poiName}
                                                </div>
                                                <div className="side-flow__rank__list__item__count">
                                                    {el.poiCount}
                                                </div>
                                            </div>
                                            <div className="side-flow__rank__list__item__percent">
                                                <div
                                                    style={{
                                                        width: `${
                                                            (el.poiCount /
                                                                el.poiTotalCount) *
                                                            100
                                                        }%`,
                                                    }}
                                                    className="side-flow__rank__list__item__percent__cur"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default inject(
    "StoreFlow",
    "StoreFloor",
    "StoreGlobal"
)(observer(SideFlow));
